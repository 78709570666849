//@ts-check
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/slices/currentUser";

/**
 * @typedef  {import("../firebase/firestore").UserState} UserState
 */


export function useUser() {
  let dispatch = useDispatch();

  /**
   * @type {UserState} 
   */
  //@ts-ignore
  const user = useSelector((state) => state.currentUser.value);
  /**
   *
   * @param {UserState} data Dispatch function to set User state in redux store
   * @returns {void}
   */
  const updateData = (data) => {
    dispatch(
      setUser({
        ...user,
        ...data,
      })
    );
  };
  return { user, updateData };
}
