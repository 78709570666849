import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

/**
 * @typedef {import("..").AuthContextValue} AuthContextValue
 */

/**
 * Firebase authentication context hook
 *
 * @returns {AuthContextValue}
 */
export function useAuth() {
  return useContext(AuthContext);
}
