import styles from "./Navbar.module.css";
import { navLinks } from "../../navLinks/navLinks";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedOut } from "../../redux/slices/currentUser";
import { useAuth } from "../../hooks/auth";
import { useUser } from "../../hooks/useUser";

export default function Navbar(props) {
  const dispatch = useDispatch();
  const { user } = useUser();
  const { logout } = useAuth();

  const isLoggedin = useSelector((state) => state.currentUser.loggedIn);
  let location = useLocation();
  const [navOverlay, setNavOverlay] = useState(false);

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showDropdown, setShowDropdown] = useState({
    programs: false,
    profile: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const controlNavbar = () => {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlNavbar);

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  });
  const handleDropdownHover = (dropdownName, state) => {
    setShowDropdown({
      ...showDropdown,
      [dropdownName]: state,
    });
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        dispatch(setLoggedOut());
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    navOverlay && (document.body.style.overflow = "hidden");
    !navOverlay && (document.body.style.overflow = "unset");
  }, [navOverlay]);

  return (
    <nav
      className={styles.nav}
      style={{ position: !show ? "relative" : undefined }}
    >
      <img
        className={styles.desktopOnly}
        src="/images/logo/no-bg.png"
        alt="Nile MSSN logo"
        onClick={() => navigate("/")}
      />
      <img
        className={styles.mobileOnly}
        src="/images/logo/with-name-no-bg.png"
        alt="Nile MSSN logo"
        onClick={() => navigate("/")}
      />
      <div className={`${styles.links} ${styles.desktopOnly}`}>
        {navLinks.map((link, index) => {
          return (
            <Link
              className={
                location.pathname === link.path ? styles.active : undefined
              }
              key={index}
              to={link.path}
            >
              <div>{link.text}</div>
            </Link>
          );
        })}
      </div>
      {isLoggedin ? (
        <>
          <img
            onMouseEnter={() => handleDropdownHover("profile", true)}
            onMouseLeave={() => handleDropdownHover("profile", false)}
            className={styles.desktopOnly}
            src={user.photoURL}
            alt="Avatar"
            onClick={() => navigate("/profile")}
          />
          {!navOverlay && (
            <div className={styles.dropdown}>
              {showDropdown.profile && (
                <ul
                  onMouseEnter={() => handleDropdownHover("profile", true)}
                  onMouseLeave={() => handleDropdownHover("profile", false)}
                >
                  {/* Add your program links here */}
                  <Link to="/profile">
                    <li>Profile</li>
                  </Link>
                  <Link to="/" onClick={handleLogout}>
                    <li>Logout</li>
                  </Link>
                  {/* ... */}
                </ul>
              )}
            </div>
          )}
        </>
      ) : (
        <div className={`${styles.buttons} ${styles.desktopOnly}`}>
          <button onClick={() => navigate("/sign-up")}>Sign Up</button>
          <button onClick={() => navigate("/login")}>Log In</button>
        </div>
      )}
      <img
        className={styles.mobileOnly}
        src="/images/icons/menu.svg"
        alt="menu"
        onClick={() => setNavOverlay(true)}
      />
      {navOverlay && (
        <div
          className={`${styles.links} ${styles.mobileOnly}`}
          style={{ justifyContent: !isLoggedin ? "center" : undefined }}
        >
          <img
            src={`./images/icons/cancel${isLoggedin ? "-white" : ""}.svg`}
            alt="cancel"
            onClick={() => setNavOverlay(false)}
          />
          {isLoggedin && (
            <div className={styles.profile}>
              <img
                src={user.photoURL}
                alt="Avatar"
                onClick={() => navigate("/profile")}
              />
              <h2>{user.fullName}</h2>
            </div>
          )}
          <div>
            {!isLoggedin && (
              <div className={styles.buttons}>
                <button
                  onClick={() => {
                    setNavOverlay(false);
                    navigate("/sign-up");
                  }}
                >
                  Sign Up
                </button>
                <button
                  onClick={() => {
                    setNavOverlay(false);
                    navigate("/login");
                  }}
                >
                  Log In
                </button>
              </div>
            )}
            {navLinks.map((link, index) => {
              return (
                <div
                  onClick={() => {
                    setNavOverlay(false);
                  }}
                  key={index}
                >
                  <Link to={link.path}>
                    <img src={link.img} alt={link.text} />
                    {link.text}
                  </Link>
                </div>
              );
            })}
            {isLoggedin && (
              <>
                <div
                  onClick={() => {
                    setNavOverlay(false);
                  }}
                >
                  <Link to="/profile">
                    <img src="/images/icons/profile.svg" alt="Avatar" />
                    Profile
                  </Link>
                </div>
                <div
                  onClick={() => {
                    handleLogout();
                    setNavOverlay(false);
                  }}
                >
                  <Link to="/">
                    <img src="/images/icons/logout.svg" alt="logout" />
                    Logout
                  </Link>
                </div>
              </>
            )}
          </div>
          <img
            src="/images/logo/no-bg.png"
            alt="Nile MSSN logo"
            onClick={() => navigate("/")}
          />
        </div>
      )}
    </nav>
  );
}
