import React, { useEffect } from "react";
import "firebaseui/dist/firebaseui.css";
import styles from "../components/AuthPage/AuthPage.module.css";
import * as firebaseui from "firebaseui";
import { auth, emailProvider, googleProvider } from "../firebase/config";

const FirebaseAuth = () => {
  useEffect(() => {
    // Check if `auth` is not undefined
    if (auth) {
      // Set the language code (optional)
      auth.languageCode = "en";

      // Initialize FirebaseUI Auth
      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // Action if the user is authenticated successfully
          },
          uiShown: function () {
            // This is what should happen when the form is full loaded. In this example, I hide the loader element.
            document.getElementById("loader").style.display = "none";
          },
        },
        signInFlow: "popup",
        signInSuccessUrl: "./", // This is where should redirect if the sign in is successful.
        signInOptions: [
          // This array contains all the ways an user can authenticate in your application. For this example, is only by email.
          {
            provider: emailProvider.providerId,
            requireDisplayName: true,
            disableSignUp: {
              status: true,
            },
          },
          googleProvider.providerId,
        ],
        tosUrl: "./terms-conditions", // URL to you terms and conditions.
        privacyPolicyUrl: function () {
          // URL to your privacy policy
          window.location.assign("./privacy-policy");
        },
      };

      // Ensure that the AuthUI instance is initialized once
      let ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(auth);

      // Start FirebaseUI Auth
      ui.start("#firebaseui-auth-container", uiConfig);

      // Clean up the instance when the component unmounts
      return () => {
        ui.reset();
      };
    } else {
      console.error("Firebase Auth not initialized.");
    }
  }, []);

  return (
    <div
      style={{
        display: "grid",
        placeContent: "center",
        height: "100vh",
        color: "rgb(0,0,0,0.8)",
        background: "rgb(0,0,0,0.1)",
      }}
    >
      <h1 className="text-center my-3 title">Admin Login</h1>
      <div className={styles.form} id="firebaseui-auth-container"></div>
      <div id="loader" className="text-center">
        Loading form
      </div>
    </div>
  );
};

export default FirebaseAuth;
