import { combineReducers, configureStore } from "@reduxjs/toolkit";
import verseReducer from "./slices/verse";
import hadithReducer from "./slices/hadith";
import duaReducer from "./slices/dua";
import prayerTimesReducer from "./slices/prayerTimes";
import currentUserReducer from "./slices/currentUser";
import postStateReducer from "./slices/postState";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  verse: verseReducer,
  hadith: hadithReducer,
  dua: duaReducer,
  solat: prayerTimesReducer,
  postState: postStateReducer,
});

const persistConfig = {
  key: "root",
  storage,
  version: 1,
  blacklist: ["verse", "hadith", "dua", "postState"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;
