import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    title: "When Leaving the House",
    arabic:
      "بِسْمِ اللهِ تَوَكَّلْـتُ عَلى اللهِ وَلا حَوْلَ وَلا قُـوَّةَ إِلاّ بِالله",
    transliteration:
      "Bismillah, tawakkaltu 'alallah, walā hawla walā quwwata illā billah",
    translation:
      "In the Name of Allah, I have placed my trust in Allah, there is no might and no power except by Allah.",
    source: "Hisn al-Muslim 16",
  },
};

const dua = createSlice({
  name: "dua",
  initialState,
  reducers: {
    setDua: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setDua } = dua.actions;
export default dua.reducer;
