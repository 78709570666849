//@ts-check
import bcrypt from "bcryptjs";

/**
 *
 * @typedef {import("..").PostAudience} PostAudience interface
 *
 */

export async function hash(string) {
  const saltRounds = 10; // Adjust salt rounds for desired security level
  const hash = await bcrypt.hash(string, saltRounds);
  return hash;
}

/**
 *
 * @param {string} string
 * @param {string} hash
 * @returns
 */
export async function hashCompare(string, hash) {
  if (!hash || !string) throw new Error("Error validating password");
  return bcrypt.compare(string, hash);
}

/**
 * Generates a random 4-digit OTP.
 *
 * @returns {string} The randomly generated 4-digit OTP.
 */
export const generateOTP = () => {
  // returns a random 4-digit as OTP
  return Math.floor(1000 + Math.random() * 9000).toString();
};

/**
 *
 * @param {number} time
 * @returns
 */
export const toMins = (time) => {
  let seconds = time % 60;
  let minutes = Math.floor(time / 60);
  return `${minutes} : ${seconds < 10 ? "0" : ""}${seconds}`;
};

/**
 * @typedef {import("..").RawComment} RawComment
 * @typedef {import("..").PostComment} PostComment
 */

/**
 * Nests children comments to their parents.
 *
 * @param {RawComment[]} comments Array of raw comments from firestore.
 * @returns {PostComment[]} Formated Array of comments.
 */
export const formatComments = (comments) => {
  console.log(comments);
  if (comments.length === 0) return comments;

  const parentMap = new Map(); // Use Map for constant-time lookups
  const formattedComments = [];

  for (const comment of comments) {
    if (!comment.parentId) {
      comment.children = []; // Initialize children array
      parentMap.set(comment.id, comment);
      formattedComments.push(comment);
    } else {
      const parent = parentMap.get(comment.parentId);
      if (parent) {
        parent.children.push(comment);
      } else {
        // Orphaned comment (no parent found) - potentially log or handle differently
      }
    }
  }

  return formattedComments;
};

export const getTimeDifference = (timestamp) => {
  const stampTime = new Date(timestamp);
  const now = new Date().getTime();
  const diff = now - timestamp * 1000;

  if (diff < 60000) {
    // Less than 1 minute
    return "just now";
  } else if (diff < 3600000) {
    // Less than 1 hour
    const minutes = Math.floor(diff / 60000);
    return `${minutes} minutes ago`;
  } else if (diff < 86400000) {
    // Less than 1 day
    const hours = Math.floor(diff / 3600000);
    return `${hours} hours ago`;
  } else {
    return `${stampTime.getUTCDate()}/${
      stampTime.getUTCMonth() + 1
    }/${stampTime.getUTCFullYear()}`;
  }
};

/**
 *
 * @param {string} uid
 * @param {import("../firebase/firestore").PostAudience[]} postAudience
 * @returns
 */
export const checkAudience = (uid, postAudience) => {
  //@ts-ignore
  const found = postAudience.indexOf((entry) => entry.uid === uid);
  if (found !== -1) {
    if (postAudience[found].reacted === true) {
      return true;
    }
  }
  return false;
};

export const clog = (...args) => {
  args.forEach((message, index) => {
    console.log("Message " + (index + 1) + ":", message);
  });
};
