// @ts-check
import { hash, generateOTP, formatComments } from "../utils/compute";
import {
  validateComment,
  validatePost,
  validateUserData,
} from "../utils/validate";
import { db } from "./config";
import {
  collection,
  getDocs,
  deleteDoc,
  getDoc,
  setDoc,
  doc,
  query,
  where,
  updateDoc,
  orderBy,
  limit,
  addDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";

/**
 *
 * @typedef {Object} DocumentData
 */

/**
 * @typedef {import("..").RawComment} RawComment
 * @typedef {import("..").PostComment} PostComment
 * @typedef {import("..").PostData} PostData
 * @typedef {import("..").CheckUser} CheckUser
 * @typedef {import("..").PrayerTimes} PrayerTimes
 * @typedef {import("..").PostAudience} PostAudience
 * @typedef {import("..").UserState} UserState
 * @typedef {import("..").EmailBody} EmailBody
 * @typedef {import("firebase/firestore").OrderByDirection} OrderByDirection
 * @typedef {import("firebase/firestore").WhereFilterOp} WhereFilterOp
 * @typedef {import("firebase/firestore").QuerySnapshot} QuerySnapshot
 * @typedef {import("firebase/firestore").Unsubscribe} Unsubscribe
 * @typedef {import("firebase/firestore").FirestoreError} FirestoreError
 */

// USER FUNCTIONS
//--------------------------------------------------------------

/**
 * Gets a firestore document using the provided collection and path segments
 *
 * @param {string} path -  A slash-separated path to a document.
 * @param {string[]} args -  Additional path segments that will be applied relative to the first argument.
 * @returns {Promise<DocumentData|undefined>} An `Object` containing all fields in the document or `undefined` if the document doesn't exist.
 */
export async function fetchDoc(path, ...args) {
  try {
    const docRef = doc(db, path, ...args);
    const snapshot = await getDoc(docRef);
    if (!snapshot.exists) {
      return null;
    }

    return snapshot.data;
  } catch (error) {
    console.error("Error fetching document:", error);
    return undefined; // Return null in case of errors
  }
}

/**
 * Gets a firestore document using the provided collection and query filters
 *
 * @param {string} path -  A slash-separated path to a collection.
 * @param {string} field -  The field path to compare
 * @param {string} value -  The value for comparison
 * @returns {Promise<DocumentData|null>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export async function fetchDocQuery(path, field, value) {
  const userColRef = collection(db, path);
  const q = query(userColRef, where(field, "==", value));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }
  if (querySnapshot.size !== 1) {
    throw new Error("Duplicate documents found!");
  }

  return querySnapshot.docs[0].data();
}

/**
 * Gets firestore documents with a filter order applied
 *
 * @param {string} path -  A slash-separated path to a collection.
 * @param {string} field -  The field to sort by.
 * @param {number} lim -  The maximum number of items to return.
 * @param {OrderByDirection|undefined} order -  Optional direction to sort by ('asc' or 'desc'). If not specified, order will be ascending.
 * @returns {Promise<DocumentData[]|[]>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export async function fetchDocsOrder(path, field, lim, order = undefined) {
  const colectionRef = collection(db, path);
  const q = query(colectionRef, orderBy(field, order), limit(lim));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  }
  return querySnapshot.docs.map((snapshot) => snapshot.data());
}

/**
 * Gets firestore documents using the provided collection and query filters
 *
 * @param {string} path -  A slash-separated path to a collection.
 * @param {string} field -  Array of 3 query filter items
 * @param {WhereFilterOp} opStr -  Array of 3 query filter items
 * @param {string} value -  Array of 3 query filter items
 * @param {number} lim -  The maximum number of items to return.
 * @param {OrderByDirection|undefined} order -  Optional direction to sort by ('asc' or 'desc'). If not specified, order will be ascending.
 * @returns {Promise<DocumentData[]|[]>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export async function fetchDocsWithFilters(
  path,
  field,
  opStr,
  value,
  lim,
  order = undefined
) {
  const collectionRef = collection(db, path);
  const q = query(
    collectionRef,
    where(field, opStr, value),
    orderBy(field, order),
    limit(lim)
  );
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  }
  return querySnapshot.docs.map((snapshot) => snapshot.data());
}

/**
 * Gets all documents in a firestore collection using the provided collection path
 *
 * @param {string} path -  A slash-separated path to a collection.
 * @param {string[]} args -  Additional path segments to apply relative to the first argument.
 * @returns {Promise<DocumentData[]|[]>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export async function fetchCollection(path, ...args) {
  const collectionRef = collection(db, path, ...args);
  const querySnapshot = await getDocs(collectionRef);

  if (querySnapshot.empty) {
    return [];
  }
  return querySnapshot.docs.map((snapshot) => snapshot.data());
}

/**
 * Creates a new firestore document in a collection and automatically assigns an id
 *
 * @param {string} path -  A slash-separated path to a colection.
 * @param {object} data - An Object containing the data for the new document.
 * @returns {Promise<string>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export const createDoc = async (path, data) => {
  const docRef = collection(db, path);
  const newDocRef = await addDoc(docRef, data);
  await setDoc(newDocRef, { id: newDocRef.id }, { merge: true });
  return newDocRef.id;
};

/**
 * Creates a new firestore document from the data provided and doc id
 *
 * @param {string} path -  A slash-separated path to a document.
 * @param {string} id -  An id to name the document.
 * @param {object} data - An Object containing the data for the new document.
 * @returns {Promise<void>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export const createDocWithId = async (path, id, data) => {
  const docRef = doc(db, path, id);
  await setDoc(docRef, data);
};

/**
 * Updates a firestore document with the provided data.
 *
 * @param {string} path -  A slash-separated path to a document.
 * @param {object} data - An object containing the fields and values with which to update the document. Fields can contain dots to reference nested fields within the document.
 * @returns {Promise<void>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export const modifyDoc = async (path, data) => {
  const docRef = doc(db, path);
  await updateDoc(docRef, data);
};

/**
 * Deletes a firestore document.
 *
 * @param {string} path -  A slash-separated path to a document.
 * @returns {Promise<void>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export const removeDoc = async (path) => {
  const docRef = doc(db, path);
  await deleteDoc(docRef);
};

/**
 * Checks if a user exists in the database based on the provided userId.
 * @param {string} userId - The unique identifier of the user to check.
 * @returns {Promise<CheckUser>} An object containing a boolean 'exists' field indicating if the user exists,
 * and the 'userData' field with user Data if the user exists, otherwise 'null'.
 */
export const checkUser = async (userId) => {
  try {
    const userData = await fetchDocQuery("users", "uid", userId);
    if (userData) {
      return { exists: true, userData: userData };
    } else {
      return { exists: false };
    }
  } catch (error) {
    console.error(error);

    return { exists: false };
  }
};

/**
 * Adds a new user to the Firestore database if the user does not already exist.
 *
 * @param {string} uid - The unique identifier for the user.
 * @param {UserState} userData - The data of the user to be added.
 * @returns {Promise<void>} A promise that resolves with the newly added user information if successful,
 * or null if the user already exists.
 * @throws {Error} If there is an error adding the user to the database.
 */
export const addUser = async (uid, userData) => {
  const { exists } = await checkUser(uid);

  if (!exists) {
    const formattedUserData = { ...userData, uid };
    await createDocWithId("users", uid, validateUserData(formattedUserData));
    console.log("User added successfully");
  } else {
    throw new Error("user-already-exists");
  }
};

/**
 * Updates a user's profile data in the database.
 *
 * @param {string} uid  The ID of the user to update.
 * @param {UserState} userData - The user data to update.
 * @returns  - A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline)..
 */
export const updateUser = async (uid, userData) => {
  const { exists } = await checkUser(uid);

  if (exists) {
    await modifyDoc(`users/${uid}`, userData);
    console.log("user updated");
    return;
  } else {
    throw new Error("Error updating user");
  }
};

// export const deleteUser = async (uid) => {
//   try {
//     const { exists } = await checkUser(uid);

//     if (exists) {
//       console.log("user found!");
//       await removeDoc(`users/${uid}`);
//       console.log("user deleted!");
//       alert("user deleted!");
//     } else {
//       alert("User not found!");
//     }
//   } catch (err) {
//     console.error("Error deleting user: ", err.message);
//   }
// };

/**
   * Sends the body of an email to user email.
   * 
   * @param {EmailBody} body - The email body object.
   * @returns {Promise<boolean>} True if email sent, otherwise false.
   * 
   * @example
   *   const body = {
      to: "attahir.nasir@gmail.com",
      template: {
        name: "welcome",
        data: {
          userName: "Attahiru",
          phoneNumber: "09024061250"
        }
      }
    }
   */
export const sendEmail = async (body) => {
  try {
    await createDocWithId("emails", body.to, body);
    console.log("Email queued for delivery!");
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 *
 * @param {string} email
 * @param {string} otp
 * @returns
 */
export const sendOTPEmail = async (email, otp) => {
  try {
    const emailBody = {
      to: email,
      template: {
        name: "otp",
        data: {
          otp_code: otp,
          expiration_time: 2,
          app_name: "Nile MSSN",
        },
      },
    };

    const status = await sendEmail(emailBody);
    if (status) {
      console.log(`OTP sent to ${email}!`);
      return true;
    } else {
      throw new Error("Failed to send OTP. Please try again later.");
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 *
 * @param {string} uid  The ID of the user.
 * @param {string} email
 * @returns
 */
export const proceedWithOTP = async (uid, email) => {
  // generate the opt
  const otp = generateOTP();

  // Save otp to database
  await updateUser(uid, { otp });

  // send the otp as email
  const status = await sendOTPEmail(email, otp);
  if (status) {
    return;
  } else {
    throw new Error("Failed to send OTP. Please try again later.");
  }
};

/**
 * Resends OTP to the user's email after generating a new OTP.
 *
 * @param {string} uid - The email address of the user to resend OTP.
 * @returns {Promise<Boolean|undefined>} - Returns 'successful' if OTP is sent successfully.
 * @throws {Error} - If there is an error in resending OTP or user not found.
 */
export const sendUserOTP = async (uid) => {
  const otp = generateOTP();
  const { exists, userData } = await checkUser(uid);

  if (exists) {
    // @ts-ignore
    await updateUser(userData.uid, { otp });

    // @ts-ignore
    const status = await sendOTPEmail(userData.email, otp);
    if (status) {
      return status;
    } else {
      throw new Error("Failed to send OTP. Please try again later.");
    }
  }
};

export const updateUserPassword = async (uid, password) => {
  try {
    const passwordHash = await hash(password);
    const { userData } = await checkUser(uid);
    // @ts-ignore
    await updateUser(userData.uid, { passwordHash });
    return passwordHash;
  } catch (error) {
    throw new Error("Failed to update user password!");
  }
};

/**
 *
 * @param {string} uid  The ID of the user.
 * @param {string} code
 * @returns
 */
export const verifyOTP = async (uid, code) => {
  const { exists, userData } = await checkUser(uid);
  if (exists) {
    const sentOTPCode = userData?.otp;
    if (code === sentOTPCode) {
      return true;
    } else {
      return false;
    }
  } else {
    throw new Error("User not found");
  }
};

/**
 *
 * Fetch prayer times from firestore database
 *
 * @returns {Promise<PrayerTimes|null>}
 */
export async function getPrayerTimes() {
  try {
    const data = await fetchDoc("app-state", "prayer-times");
    return data;
  } catch (error) {
    console.error("Error getting prayer times:", error);
    return null;
  }
}

export const getCharityEvents = async (num) => {
  const results = await fetchDocsWithFilters(
    "events",
    "eventType",
    "==",
    "charity",
    num
  );
  return results;
};

/**
 *
 * @param {string} uid
 * @param {Object} formData
 */
export const registerIslamiyyaStudent = async (uid, formData) => {
  const { exists } = await checkUser(uid);

  if (!exists) {
    throw new Error("Unauthorized-user");
  }

  await createDocWithId(
    "Islamiyya-students",
    `${formData.firstName.trim().toUpperCase()}_${formData.lastName
      .trim()
      .toUpperCase()
      .replace(" ", "_")}`,
    formData
  );
};

/**
 *
 * @param {string} postId
 * @returns
 */
export const getComents = async (postId) => {
  const comments = await fetchCollection("posts", postId, "comments");
  return formatComments(comments);
};

/**
 *
 * Fetch post from firestore database
 *
 * @param {string} postSlug URL slug for a post
 * @returns {Promise<PostData|null>}
 */
export const getBlogPost = async (postSlug) => {
  try {
    const post = await fetchDocQuery("posts", "slug", postSlug);

    return post;
  } catch (error) {
    console.error("Error fetching post:", error);
    return null; // Return null in case of errors
  }
};

/**
 *
 * @param {string} field
 * @param {number} lim
 * @param {OrderByDirection} order
 * @returns {Promise<PostData[]>}
 */
export const getPosts = async (field, lim = 5, order = "desc") => {
  try {
    const results = await fetchDocsOrder("posts", field, lim, order);
    return results;
  } catch (error) {
    console.error("Error fetching posts:", error);
    return [];
  }
};

export const getPrograms = async (field, lim = 1, order = undefined) => {
  try {
    const results = await fetchDocsOrder("programs", field, lim, order);
    return results;
  } catch (error) {
    console.error("Error fetching programs:", error);
    return [];
  }
};

/**
 *
 * @param {string} postTags
 * @param {number} lim
 * @returns {Promise<PostData[]>}
 */
export const getRelatedPostsByTags = async (postTags, lim = 5) => {
  try {
    const results = fetchDocsWithFilters(
      "posts",
      "tags",
      "array-contains-any",
      postTags,
      lim
    );
    return results;
  } catch (error) {
    console.error("Error fetching posts:", error);
    return [];
  }
};

/**
 *
 * @param {PostData} post
 * @returns {Promise<string?>}
 */
export const addPost = async (post) => {
  try {
    const postId = await createDoc(
      "posts",
      validatePost({ ...post, createdAt: serverTimestamp() })
    );
    return postId;
  } catch (error) {
    console.error("Error adding post:", error);
    return null;
  }
};

/**
 *
 * @param {PostComment} comment
 * @param {string} parentId
 * @returns {Promise<string?>}
 */
export const addComment = async (comment, parentId) => {
  const formatData = { ...comment, createdAt: serverTimestamp() };
  try {
    const commentId = await createDoc(
      `posts/${comment.postId}/comments`,
      validateComment(formatData)
    );
    return commentId;
  } catch (error) {
    console.error("Error adding post:", error);
    return null;
  }
};

/**
 *
 * @param {string} postId Post ID of the post document
 * @param {PostAudience} data An Object containing the data for the new document.
 * @returns {Promise<string?>}
 */
export const addAudience = async (postId, data) => {
  const formatData = { ...data, createdAt: serverTimestamp() };
  try {
    const id = await createDoc(`posts/${postId}/audience`, formatData);
    return id;
  } catch (error) {
    console.error("Error adding post:", error);
    return null;
  }
};

/**
 * Updates a post document with the provided data.
 *
 *
 * @param {string} postId - Post ID of the post document
 * @param {PostData} data - An object containing the fields and values with which to update the document. Fields can contain dots to reference nested fields within the document.
 * @param {Boolean} simple - (Optional) indicates a minor update
 * @returns {Promise<void>} A Promise resolved once the data has been successfully written to the backend (note that it won't resolve while you're offline).
 */
export const updatePost = async (postId, data, simple = false) => {
  const formatData = simple
    ? { ...data }
    : { ...data, updatedAt: serverTimestamp() };
  try {
    await modifyDoc(`posts/${postId}`, validatePost(formatData));
  } catch (error) {
    console.error("Error adding post:", error);
  }
};

/**
 *
 * @param {PostComment} data
 */
export const editComment = async (data) => {
  const formatData = { ...data, updatedAt: serverTimestamp() };
  try {
    await modifyDoc(
      `posts/${data.postId}/comments/${data.id}`,
      validatePost(formatData)
    );
  } catch (error) {
    console.error("Error adding post:", error);
  }
};

/**
 *
 * @param {string} postId
 * @returns {Promise<PostAudience[]>}
 */
export const getAudience = async (postId) => {
  console.log();
  const res = await fetchCollection("posts", postId, "audience");
  return res;
};

/**
 *
 * @param {string} postId
 * @param {PostAudience} data
 */
export const updateAudience = async (postId, data) => {
  const formatData = { ...data, updatedAt: serverTimestamp() };
  try {
    if (!data?.id) throw new Error("Id is required in data");

    await modifyDoc(`posts/${postId}/audience/${data.id}`, formatData);
  } catch (error) {
    console.error("Error updating audience:", error);
  }
};

/**
 *
 * @param {string} postId
 * @param {string} commentId
 */
export const deleteComment = async (postId, commentId) => {
  try {
    await removeDoc(`posts/${postId}/comments/${commentId}`);
  } catch (error) {
    console.error("Error adding post:", error);
  }
};

/**
 *
 * @param {String} path A slash-separated path to a collection.
 * @param {(snapshot: QuerySnapshot) => void} onNext A callback to be called every time a new `QuerySnapshot`
 * @param { (error: FirestoreError) => void} onError A callback to be called if the listen fails or is
 * cancelled. No further callbacks will occur.
 * @returns {Unsubscribe} An unsubscribe function that can be called to cancel the snapshot listener.
 */
export const onDocUpdate = (path, onNext, onError) => {
  const collectionRef = collection(db, path);
  const q = query(collectionRef, orderBy("createdAt", "asc"));
  return onSnapshot(q, onNext, onError);
};
