import { createSlice } from "@reduxjs/toolkit";
import { updatePost } from "../../firebase/firestore";

const initialState = {
  post: {
    title: "",
    featured: {
      src: "",
      type: "image",
      alt: "",
    },
    content: "",
    excerpt: "",
    status: "published",
    author: {
      displayName: "",
      role: "USER",
      uid: "",
      email: "",
    },
    tags: [],
    category: "",
    views: 0,
    reactions: [],
    slug: "",
  },
  comments: [],
  audience: [],
  lastComment: "",
};

const postState = createSlice({
  name: "postState",
  initialState,
  reducers: {
    setPostState: (state, action) => {
      state.post = action.payload;
    },
    setAudienceState: (state, action) => {
      state.audience = action.payload;
    },
    addEmoji: (state, action) => {
      const lookup = state.post.reactions.findIndex(
        (reaction) => reaction.emoji === action.payload
      );

      if (lookup >= 0) {
        state.post.reactions[lookup].count += 1;
      } else {
        state.post.reactions = [{ emoji: action.payload, count: 1 }] || [
          state.post.reactions,
          ...[{ emoji: action.payload, count: 1 }],
        ];
      }
      updatePost(state.post.id, { reactions: state.post.reactions }, true);
    },
    removeEmoji: (state, action) => {
      const lookup = state.post.reactions.findIndex(
        (reaction) => reaction.emoji === action.payload
      );

      if (lookup >= 0) {
        state.post.reactions[lookup].count -= 1;
      }
    },
    addLastComment: (state, action) => {
      state.lastComment = action.payload;
    },
    clearLastComment: (state) => {
      state.lastComment = "";
    },
  },
});

export const {
  setPostState,
  setAudienceState,
  addLastComment,
  removeEmoji,
  addEmoji,
  clearLastComment,
} = postState.actions;
export default postState.reducer;
