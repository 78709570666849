import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "./hooks/auth";
import { setLoggedOut } from "./redux/slices/currentUser";
import MainRoutes from "./routes/MainRoutes";
import MaintenanceRoutes from "./routes/MaintenanceRoutes";
// import { postsSample } from "./sample";
// import { addPost } from "./firebase/firestore";
// postsSample.forEach(async (post) => {
//   return addPost(post).then(() => {
//     console.log("Post added:", post.title);
//   });
// });

export default function App() {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  const [underMaintenance, setUnderMaintenance] = useState(false); // eslint-disable-line no-unused-vars

  if (!currentUser) {
    dispatch(setLoggedOut());
  }

  return <>{underMaintenance ? <MaintenanceRoutes /> : <MainRoutes />};</>;
}
