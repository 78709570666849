import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    fullName: "",
    email: "",
    photoURL: "./images/dummy-client/1.png",
    dept: "",
    level: "",
    schoolId: "",
    rememberMe: true,
    userRole: "ANONYMOUS",
  },
  loggedIn: false,
};

const currentUser = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
    setLoggedIn: (state) => {
      state.loggedIn = true;
      if (state.value.rememberMe !== true) {
        state.value = initialState.value;
      }
    },
    setLoggedOut: (state) => {
      state.loggedIn = false;
      state.value = initialState.value;
    },
  },
});

export const { setUser, setLoggedIn, setLoggedOut } = currentUser.actions;
export default currentUser.reducer;
