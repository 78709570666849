import React, { createContext, useState, useEffect } from "react";
import { auth, googleProvider } from "../firebase/config";
import {
  browserSessionPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  verifyPasswordResetCode,
  signOut,
} from "firebase/auth";
import { updateUserPassword } from "../firebase/firestore";

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  const [loading, setLoading] = useState(true);

  async function signup(email, password, remember = false) {
    if (!remember) await setPersistence(auth, browserSessionPersistence);
    const cred = await createUserWithEmailAndPassword(auth, email, password);
    return cred.user;
  }

  async function signInWithGoogle(remember = true) {
    if (!remember) await setPersistence(auth, browserSessionPersistence);
    const cred = await signInWithPopup(auth, googleProvider);
    return cred.user;
  }

  async function login(email, password, remember = true) {
    if (!remember) await setPersistence(auth, browserSessionPersistence);
    const cred = await signInWithEmailAndPassword(auth, email, password);
    return cred.user;
  }

  function logout() {
    console.log("User Logged Out!");
    return signOut(auth);
  }

  function onAuthState(callback) {
    return onAuthStateChanged(auth, callback);
  }

  async function sendResetEmail(email) {
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_HOSTNAME}/forgot-password?email=${email}`,
    };
    await sendPasswordResetEmail(auth, email, actionCodeSettings);
    return;
  }

  async function handleResetPassword(email, newPassword, actionCode) {
    // Verify the password reset code is valid.
    const verifiedEmail = await verifyPasswordResetCode(auth, actionCode);
    if (verifiedEmail !== email) {
      throw new Error("Failed to verify reset password request");
    }
    // Save the new password.
    const passwordHash = await updateUserPassword(
      currentUser?.uid,
      newPassword
    );
    await confirmPasswordReset(auth, actionCode, newPassword);
    return passwordHash;
  }

  //   function updateEmail(email) {
  //     return currentUser.updateEmail(email);
  //   }

  //   function updatePassword(password) {
  //     return currentUser.updatePassword(password);
  //   }

  useEffect(() => {
    function authChanged(user) {
      setCurrentUser(user);
      setLoading(false);

      console.log(user);
    }
    const subscriber = onAuthState(authChanged);
    return subscriber;
  }, []);

  const value = {
    currentUser,
    login,
    signup,
    logout,
    sendResetEmail,
    // submitUserData,
    // updateEmail,
    // updateUserData,
    // updatePassword,
    signInWithGoogle,
    handleResetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
