import styles from "./Loader.module.css";

const Loader = ({ hasHeader = undefined }) => {
  return (
    <div className={styles.loaderContainer}>
      <div
        className={styles.loader}
        style={{ marginTop: hasHeader ? "-80px" : "0px" }}
      ></div>
    </div>
  );
};

export default Loader;
