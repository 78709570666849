import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";

import Loader from "../components/Loader/Loader";
import Navbar from "../components/Navbar/Navbar";

const Home = lazy(() => import("../pages/Home"));
const LogIn = lazy(() => import("../pages/LogIn"));
const AboutUs = lazy(() => import("../pages/AboutUs"));
const Programs = lazy(() => import("../pages/Programs"));
const Posts = lazy(() => import("../pages/Posts"));
const PostList = lazy(() => import("../components/PostMain/PostList/PostList"));
const PostBody = lazy(() => import("../components/PostMain/PostBody/PostBody"));
const IslamiyyaRegistration = lazy(() =>
  import("../pages/IslamiyyaRegistration")
);
const NotFound = lazy(() => import("../pages/NotFound"));
const SignUp = lazy(() => import("../pages/SignUp"));
const Profile = lazy(() => import("../pages/Profile"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const FirebaseAuth = lazy(() => import("../pages/FirebaseAuth"));

export default function MainRoutes(props) {
  let isLoggedin = useSelector((state) => state.currentUser.loggedIn);
  return (
    <Routes>
      <Route element={<WithNav />}>
        <Route index element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/islamiyya" element={<IslamiyyaRegistration />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/post" element={<Posts />}>
          <Route index element={<PostList />} />
          <Route path=":postPage" element={<PostBody />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route element={<WithoutNav />}>
        <Route
          path="/sign-up"
          element={!isLoggedin ? <SignUp /> : <Navigate to={"/"} />}
        />
        <Route
          path="/login"
          element={!isLoggedin ? <LogIn /> : <Navigate to={"/"} />}
        />
        <Route
          path="/forgot-password"
          element={!isLoggedin ? <ForgotPassword /> : <Navigate to={"/"} />}
        />
        <Route path="/dashboard" element={<FirebaseAuth />} />
      </Route>
    </Routes>
  );
}

const WithNav = () => {
  return (
    <>
      <Navbar />
      <Suspense fallback={<Loader hasHeader />}>
        <Outlet />
      </Suspense>
    </>
  );
};

const WithoutNav = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
};
